import { PageHeader } from "components";
import React from "react";

const SecurityPolicyPageHeader = () => {
  return (
    <PageHeader
      title={<>INFORMATION SECURITY POLICY STATEMENT</>}
      subheading={<>Parkway Limited</>}
      center
      theme="light"
    />
  );
};

export { SecurityPolicyPageHeader };
