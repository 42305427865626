import React from "react";
import { Container, Text } from "components";
import { StaticImage } from "gatsby-plugin-image";

const SecurityPolicyContent = () => {
  return (
    <Container>
      <div className="policy-content">
        <Text variant="p18" as="div">
          <p>
            Parkway Limited is dedicated to safeguarding the{" "}
            <span className="font-bold">CONFIDENTIALITY,</span>{" "}
            <span className="font-bold">INTEGRITY,</span> and{" "}
            <span className="font-bold">AVAILABILITY</span> of all information
            assets, aligning with legal and regulatory requirements. The
            Information Security Management System (ISMS) plays a pivotal role,
            enabling secure information processing, sharing, and storage. This
            commitment ensures compliance, meets contractual obligations, and
            upholds the organization's reputation. In tandem, Parkway Limited
            aligns Information Technology investments with organizational goals,
            adhering to ISO 27001 and PCIDSS standards for business continuity,
            cyber resilience, protection of its information assets and optimal
            returns on IT investments.
          </p>
        </Text>

        <Text value="Policy Objectives:" variant="h4" />

        <Text variant="p18" as="div">
          <p></p>
          <ul>
            <li>
              At Parkway Limited, we affirm our commitment to aligning
              information security and risk management frameworks with our
              current organizational strategy. The establishment and maintenance
              of the Information Security Management System (ISMS) play a
              crucial role in effectively managing information-related risks
              within the framework of our overall risk management strategy. This
              encompasses conducting Information Security risk assessments,
              developing a Statement of Applicability, and formulating risk
              treatment plans.
            </li>
            <li>
              Integral to this policy are business continuity and contingency
              plans, data backup procedures, access control measures, and
              incident management protocols. All employees are entrusted with
              the responsibility of promptly reporting information security
              breaches and incidents.
            </li>
            <li>
              Every employee and external party identified in the Management
              Systems must comply with this policy. Comprehensive training in
              line with this policy is mandatory, with documentation of staff
              and relevant external parties providing or undergoing training.
            </li>
            <li>
              Breach repercussions encompass disciplinary measures, including
              potential termination of employment/contract. In cases of policy
              or security mechanism breaches, legal action will be pursued
              according to the Cybercrime Prohibition Act 2015
            </li>
          </ul>
        </Text>

        <Text value="Our compliance certifications" variant="h4" />
        <Text variant="p18" as="div">
          <p>
            We proudly hold compliance certifications, adhering to industry
            standards to ensure the highest level of security and reliability.
            This reflects our ongoing commitment to excellence in safeguarding
            data.
          </p>
        </Text>

        <div className="flex max-[768px]:flex-col min-[769px]:items-end item-center gap-[30px] text-center">
          <div>
            <StaticImage
              alt="PCI compliant"
              src="../../../assets/images/pngs/security-policy/PCIDSS.png"
            />
            <Text variant="p18">
              <p className="font-bold">
                Payment Card Industry certified PCI DSS
              </p>
            </Text>
          </div>

          <div>
            <StaticImage src="../../../assets/images/pngs/security-policy/iso27001.png" />
            <Text variant="p18">
              <p className="font-bold">Management System ISO 27001</p>
            </Text>
          </div>
        </div>
      </div>
    </Container>
  );
};

export { SecurityPolicyContent };
