import * as React from "react";

import { Layout } from "components";

import {
  SecurityPolicyContent,
  SecurityPolicyPageHeader,
} from "templates/security-policy";

const SecurityPolicyPage = () => (
  <Layout title="Security Policy" theme="light">
    <SecurityPolicyPageHeader />
    <SecurityPolicyContent />
  </Layout>
);

export default SecurityPolicyPage;
